import { useUser } from '~/context/UserContext';
import Google from '~/icons/Google';

import { Form } from '@remix-run/react';

import styles from './SignUpCard.module.scss';

const SignUpCard = () => {
  const { user } = useUser();

  if (user) {
    return <></>;
  }
  return (
    <div className={styles.card}>
      <div className={styles.cardTop}>
        <h3 className={styles.heading}>Explore Your Interests</h3>
        <div className={styles.description}>
          <p>
            Create an account and enjoy content that interests you with your
            personalized feed
          </p>
        </div>
      </div>
      {/* <Link to='/' className={styles.emailButton}>
        Sign Up With Email
      </Link> */}
      {/* <div className={styles.orHolder}>
        <div className={styles.orLine} />
        or
        <div className={styles.orLine} />
      </div> */}
      <div className={styles.socialButtons}>
        <Form method='post' action='/login'>
          <button
            type='submit'
            name='provider'
            value='google'
            className={styles.socialButton}
          >
            <Google />
            Continue with Google
          </button>
        </Form>
        {/* <Link to='/' className={styles.socialButton}>
          <Apple />
          Continue with Apple
        </Link> */}
      </div>
      {/* <div className={styles.logInHolder}>
        <div className={styles.orLine} />
        <Link className={styles.logIn} to='/'>
          Log In
        </Link>
        <div className={styles.orLine} />
      </div> */}
    </div>
  );
};

export default SignUpCard;
